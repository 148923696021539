import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import * as orderActions from '../../actions/order';
import brandEnum from '../../enums/brandEnum';
import countryCodeEnum from '../../enums/countryCodeEnum';
import * as selectors from '../../sagas/selectors';

import Image from '../../containers/Image';
import Button from '../Button';
import Currency from '../Currency';
import EnvQueryComponent from '../EnvQueryComponent';
import FontAwesome from '../FontAwesome';
import Modal from '../Modal';

import dbsLogo from '../../images/dbs-logo.png';
import { ReactComponent as InfoLogo } from './infoIcon.svg';

import './CarbonOffset.css';

function CarbonOffset() {
  const dispatch = useDispatch();

  const [isCarbonOffsetEnabled, setCarbonOffsetEnabled] = useState(
    useSelector(selectors.getIsCarbonOffsetEnabled),
  );
  const [isCarbonOffsetModalOpen, setCarbonOffsetModalOpen] = useState(false);

  const carbonOffsetFee = useSelector(selectors.getCarbonOffsetFee);
  const isClimatePositiveHidden = useSelector(selectors.getIsClimatePositiveHidden);

  const handleToggleCarbonOffsetModal = () => {
    setCarbonOffsetModalOpen(!isCarbonOffsetModalOpen);
  };

  const handleToggleEnableCarbonOffset = () => {
    setCarbonOffsetEnabled(!isCarbonOffsetEnabled);
  };

  const handleEnableCarbonOffset = () => {
    setCarbonOffsetModalOpen(false);
    setCarbonOffsetEnabled(true);
  };

  useEffect(() => {
    if (isClimatePositiveHidden) {
      setCarbonOffsetEnabled(false);
      dispatch(orderActions.disableCarbonOffset());
    }
  }, [isClimatePositiveHidden, dispatch]);

  useEffect(() => {
    if (isCarbonOffsetEnabled) {
      dispatch(orderActions.enableCarbonOffset());
    } else {
      dispatch(orderActions.disableCarbonOffset());
    }
  }, [isCarbonOffsetEnabled, dispatch]);

  if (isClimatePositiveHidden) {
    return (
      <div className="HiddenClimatePositive">
        <EnvQueryComponent
          countryCode={[countryCodeEnum.SINGAPORE]}
          brand={[brandEnum.SALADSTOP]}
          showOnDesktop={false}
        >
          <div className="DbsLogoWrapper">
            <img src={dbsLogo} alt="dbs logo" className="DbsLogo" />
          </div>
        </EnvQueryComponent>
      </div>
    );
  }

  return (
    <>
      <Modal className="CarbonOffsetModal" show={isCarbonOffsetModalOpen}>
        <div className="modal-control">
          <ul>
            <li>
              <Modal.Dismiss className="btn-close-modal" onClick={handleToggleCarbonOffsetModal} />
            </li>
          </ul>
        </div>
        <Modal.Body className="CarbonOffsetInfo">
          <Image id="carbon-offset-banner" source="bannerImages" />
          <Typography variant="h4" color="secondary.dark" className="CarbonOffsetTitle">
            Climate Positive
          </Typography>
          <Typography variant="subtitle2" color="grey.main" className="CarbonOffsetDescription">
            We have partnered with green tech firm Handprint to support a mangrove reforestation
            project in Indonesia. To go climate positive means to go beyond carbon offsetting. Your
            contribution will remove
            <Box component="span" fontWeight="fontWeightNormal">
              {' '}
              20
            </Box>{' '}
            times more CO2e from the atmosphere than required to make your meal. The planet thanks
            you!
          </Typography>
          <Button variant="contained" className="btn-primary" onClick={handleEnableCarbonOffset}>
            <Box component="span" fontWeight="fontWeightBold">
              Go climate positive!
            </Box>
            <Box component="span" className="CarbonOffsetButtonPrice">
              <Currency value={carbonOffsetFee} />
            </Box>
          </Button>
        </Modal.Body>
      </Modal>
      <div className="CarbonOffsetWrapper">
        <div className="CarbonOffsetHeader">
          <Typography variant="body2" color="primary">
            Climate Positive
          </Typography>
          <InfoLogo onClick={handleToggleCarbonOffsetModal} />
        </div>
        <div className="CarbonOffsetBody">
          <Typography
            variant="subtitle1"
            className="CarbonOffsetLabel"
            color="grey.main"
            fontWeight="600"
          >
            Go climate positive!
            <Button onClick={handleToggleCarbonOffsetModal}>
              <FontAwesome name="info-circle" />
            </Button>
          </Typography>
          <Typography className="CarbonOffsetPrice" color="grey.main" variant="subtitle1">
            <Currency value={carbonOffsetFee} />
          </Typography>
          <div className="CarbonOffsetToggle">
            <label className="switch" htmlFor="SectionToggleCheckbox">
              <input
                id="SectionToggleCheckbox"
                type="checkbox"
                onChange={handleToggleEnableCarbonOffset}
                checked={isCarbonOffsetEnabled}
              />
              <div className="slider round" />
            </label>
          </div>
        </div>
        <EnvQueryComponent
          countryCode={[countryCodeEnum.SINGAPORE]}
          brand={[brandEnum.SALADSTOP]}
          showOnDesktop={false}
        >
          <div className="DbsLogoWrapper">
            <img src={dbsLogo} alt="dbs logo" className="DbsLogo" />
          </div>
        </EnvQueryComponent>
      </div>
    </>
  );
}

export default CarbonOffset;
